import { MatDateFormats } from '@angular/material/core';

export const DEFAULT_LOCALE = 'pl-PL';
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_MAT_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: `${DEFAULT_DATE_FORMAT}`,
  },
  display: {
    dateInput: `${DEFAULT_DATE_FORMAT}`,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DEFAULT_PAGINATION_SIZES = [50, 200, 250];
export const DEBOUNCE_TIME = 500;

export const INVOICESTATUSOPTIONS = [
  {value: 'WAITING_FOR_VERIFICATION', displayValue: 'Do akceptacji'},
  {value: 'WAITING_FOR_DESC', displayValue: 'Opisana FV'},
  {value: 'NEW', displayValue: 'Nowa'},
  {value: 'VERIFIED', displayValue: 'Zaakceptowana'},
  {value: 'REGISTRED', displayValue: 'Zarejestrowana'}
]
export const STATUSOPTIONS = [
  {value: 'CLOSED', displayValue: 'Zamknięty'},
  {value: 'OPEN', displayValue: 'Zarejestrowany'},
  {value: 'WAITING_FOR_DESC', displayValue: 'Oczekuje na opis'},
  {value: 'NEW', displayValue: 'Nowy'}
]

export const SETTLEMENTINNERTYPE = [
  {value: 'PAYOFF', displayValue: 'Należność'},
  {value: 'PAYMENT', displayValue: 'Zobowiązanie'},
  {value: 'INTERESTS', displayValue: 'Odsetki'}
]

export const SELECT_SETTLEMENT_INNER_TYPES = [
  {uid: 'PAYOFF', publicName: 'Należność'},
  {uid: 'PAYMENT', publicName: 'Zobowiązanie'},
  {uid: 'INTERESTS', publicName: 'Odsetki'}
]
export const SELECT_SETTLEMENT_DECREE_TYPES = [
  {uid: 'PAYOFF', publicName: 'Należność'},
  {uid: 'PAYMENT', publicName: 'Zobowiązanie'},
]

export const BASICINFOSTATUS = [
  {value: 'WAITING_FOR_VERIFICATION', displayValue: 'Oczekuje na weryfikację'},
  {value: 'VERIFIED', displayValue: 'Zweryfikowany'}
]

export const STORAGE_PUBLIC_KEY = 'panelinwestora';

export const DIALOG_WIDE = {
  disableClose: true,
  width: '90%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  position: { right: '0px' },
  panelClass: 'dialog-wide',
};
export const DIALOG_NOTES = {
  disableClose: true,
  width: '20%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  position: { right: '0px' },
};

export const DIALOG_FILTERS = {
  disableClose: true,
  width: '28%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  position: { right: '0px' },
};


export const DIALOG_FILE_PREVIEW = {
  disableClose: true,
  width: '90%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  position: { right: '0px' },
};

export const DIALOG_XL = { disableClose: true, width: '1100px' };
export const DIALOG_MD = { disableClose: true, width: '610px' };
export const DIALOG_SM = { disableClose: true, width: '450px' };

export const FILE_MAX_SIZE = 20 * 1024 * 1024; // 5MB

export const ACCENT_COLOR = '#28334a';
